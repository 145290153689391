<template>
<!-- 骨架屏 -->
  <!-- <div class="skeleton" v-show="loading">
        <div class="margin-top-100 skeleton-item">
        <div class="skeleton-title"></div>
        </div>
        <div class="margin-top-10 skeleton-item">
        <div class="skeleton-detail"></div>
        </div>
        <div class="skeleton-round-box">
        <div class="skeleton-round"></div>
        <div class="skeleton-text margin-top-10"></div>
        </div>
        <div class="skeleton-text margin-top-10"></div>

        <div class="skeleton-bottom">
        <div class="skeleton-left"></div>
        <div class="skeleton-right"></div>
        </div>
  </div> -->
  <div class="container">
    <div class="text-box">
       <div class="icon-box" :style="{marginTop:`${height}px`}">
            <div class="icon">
                 <van-icon name="arrow-left" color="#ffffff" size="28" v-show="noWX" @click="back()"/>
            </div>
            <div class="title-box">
                <div class="title">防护出行坐礼帽</div>
                <div class="title-detail">司乘隔离，一米距离更安心</div>
            </div>
        </div>
    </div>
    <div class="bottom">
        <div class="left" @click="order">
            预约用车
        </div>
        <div class="right" @click="useCar">点击开启安心出行之旅</div>
    </div>
    <img class="img" v-lazy="require('../../../assets/images/fanghu/0.jpg')" alt="" />
    <img class="img" v-lazy="require('../../../assets/images/fanghu/1.jpg')" alt="" />
    <img class="img" v-lazy="require('../../../assets/images/fanghu/2.jpg')" alt="" />
    <img class="img" v-lazy="require('../../../assets/images/fanghu/3.jpg')" alt="" />
    <img class="img" v-lazy="require('../../../assets/images/fanghu/4.jpg')" alt="" />
    <img class="img" v-lazy="require('../../../assets/images/fanghu/5.jpg')" alt="" />
     <img @click="to('quanjia')" class="change-img" v-lazy="require('../../../assets/images/large/quanjia.png')" alt="">
    <img @click="to('dajian')" class="change-img" v-lazy="require('../../../assets/images/large/large.png')" alt="">
    <img @click="to('peiban')" class="change-img" v-lazy="require('../../../assets/images/large/peiban.png')" alt="">
    <img @click="to('shangwu')" class="change-img" v-lazy="require('../../../assets/images/large/shangwu.png')" alt="">
    <img @click="to('anquan')" class="change-img" v-lazy="require('../../../assets/images/large/safe.png')" alt="">
  </div>
</template>
<script>
import { getEnv,initIosAndAndroidEnv,initIosAndAndroidCallBack,getIosAndAndroidData,initAlipayEnv} from "../../../utils/index"
import { ref,watch } from 'vue'
export default {
  data() {
    return {
      flag:false,
      height:30,
      noWX: true,
      length:1,
      loading:true
    };
  },
  computed:{
      
  },
  mounted(){
      console.log(localStorage.getItem('length'))
      if(localStorage.getItem('length')){
           //有值
           this.length = localStorage.getItem('length')
       }
      this.init()
    setTimeout(() => {
          this.loading = false
      }, 300);
  },
  methods: {
      //  返回
        back() {
            if(this.length <= 1){
                let ENV = getEnv();
                if (ENV === "ios" || ENV === "andriod") {
                    initIosAndAndroidEnv(ENV, "Other/closeNav", "back-20220914");
                }
                localStorage.removeItem("length")
                console.log("只有一个页面")
            } else {
                //调用一次 length -1
                localStorage.setItem('length',this.length - 1)
                this.$router.go(-1)
                console.log("存在多个页面")
            }
        },
      init(){
        let ENV = getEnv();
        if (ENV === "ios" || ENV === "andriod") {
            this.noWX = true
            initIosAndAndroidEnv(ENV, "navigation/visible", "family-20220913", {
            show: false,
            });
            // 状态栏颜色深浅
            initIosAndAndroidEnv(ENV, "statusBar/textColor", "family-20220913", {
            dark: false, //false 浅色
            });
            //获取状态栏高度
            initIosAndAndroidEnv(ENV, "Other/getStatusbarHeight", "height-20220913");
            getIosAndAndroidData(ENV, [
                {
                id: "height-20220913",
                callback: this.heightData,
                },
            ]);
        } else if(ENV === 'wx'){
            this.noWX = false
        } else if(ENV === 'alipay'){
            this.noWX = false
            initAlipayEnv()
        }
    },
    heightData(res){
      this.height = Number(res.height);
    },
    onClick() {
       let ENV = getEnv();
        if(ENV === "ios" || ENV === "andriod") {
            initIosAndAndroidEnv(ENV, "video/playVideo", "video-20220913", {
                url: this.url
            });
        } else if(ENV === 'wx'){
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
                url:`/pages/videoPaly/main?url=${this.url}`
            })
        }
    },
    //预约用车
    order(){
        console.log("预约用车")
        let ENV = getEnv();
        if(ENV === "ios" || ENV === "andriod") {
            initIosAndAndroidEnv(ENV, "skip/gotoView", "20220913", {
                url:"homeAppoint"
            });
        } else if(ENV === 'wx'){
            // eslint-disable-next-line no-undef
                wx.miniProgram.postMessage({ data: {
                    showMaxMap: 2,    // 地图大屏
                    orderType: 2          // 1实时 2预约
                }})
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateBack({delta: 99})
        }else if(ENV === 'alipay'){
            const showMaxMap = 2
            const orderType = 2
            // eslint-disable-next-line no-undef
            my.reLaunch({url: `/pages/index/index?showMaxMap=${showMaxMap}&orderType=${orderType}`});
        }
    },
    //开启xxx之旅
    useCar(){
        let ENV = getEnv();
        if(ENV === "ios" || ENV === "andriod") {
            initIosAndAndroidEnv(ENV, "skip/gotoView", "20220913", {
                url:"homeRealTime"
            });
        } else if(ENV === 'wx'){
             // eslint-disable-next-line no-undef
                wx.miniProgram.postMessage({ data: {
                    showMaxMap: 2,    // 地图大屏
                    orderType: 1          // 1实时 2预约
                }})
            // eslint-disable-next-line no-undef
           wx.miniProgram.navigateBack({delta: 99})
        }else if(ENV === 'alipay'){
            const showMaxMap = 2
            const orderType = 1
            // eslint-disable-next-line no-undef
            my.reLaunch({url: `/pages/index/index?showMaxMap=${showMaxMap}&orderType=${orderType}`});
        }
    },
    to(type){
        if(type==='quanjia'){
            this.$router.push('/family')
            localStorage.setItem('length',Number(this.length) + 1)
        } else if(type==='peiban'){
            localStorage.setItem('length',Number(this.length) + 1)
            this.$router.push('/accompany')
        } else if(type==='fanghu'){
            localStorage.setItem('length',Number(this.length) + 1)
            this.$router.push('/protect')
        } else if(type==='shangwu'){
            localStorage.setItem('length',Number(this.length) + 1)
            this.$router.push('/business')
        } else if(type==='anquan'){
            localStorage.setItem('length',Number(this.length) + 1)
            this.$router.push('/safe')
        } else if(type==='dajian'){
            localStorage.setItem('length',Number(this.length) + 1)
            this.$router.push('/large')
        }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/skeleton.scss";
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
   overflow: scroll;
    // background-color: #F7F9FA;
  
  video {
    width: 100%;
    // height: calc(100% - 108px);
    height: 100%;
    z-index: -100;
    display: block;
    object-fit: cover;
    overflow: hidden;
  }
   .bottom{
      background-color: #fff;
      position: fixed;
      bottom: 0;
      height: 98px;
      width: 100%;
      padding: 13px 16px;
      display: flex;
      justify-content: space-between;
      .left {
          width: 110px;
          height: 48px;
          padding: 13px 20px;
          color: #333333;
          font-size: 16px;
          border-radius: 24px;
          border: #FFEC00 solid 1px
      }
      .right {
          flex: 1;
          margin-left: 13px;
          height: 48px;
          padding: 13px 20px;
          color: #333333;
          font-size: 16px;
          border-radius: 24px;
          background-color: #FFEC00;
      }
  }
  .video-box {
    width: 100%;
    height: 100%;
    position: relative;
    .video-player {
      z-index: -100;
    }
    .close {
      z-index: 10000000;
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
    }
  }
  .img {
    width: 100%;
    display: block;
  }
  .change-img {
      padding: 0 27px;
      width: 100%;
    //   height: 100%;
    &:last-child{
        margin-bottom: 120px;
    }
  }
  .text-box {
      width: 100%;
      height: calc(100% - 108px);
      text-align: center;
      position: absolute;
      top: 0;
      color: #fff;
      .icon-box {
      .icon {
          z-index: 10000;
          position: fixed;
          text-align: left;
          padding: 20px 0 15px 15px;
      }
      .title-box {
                position: absolute;
                top:60px;
                width: 100%;

                .title {
                text-align: center;
                letter-spacing: 2px;
                font-size: 34px;
                margin-top: 40px;
                }
                .title-detail {
                text-align: center;
                margin-top: 10px;
                font-size: 16px;
                 }
            }
      .title {
          letter-spacing: 2px;
          font-size: 34px;
          margin-top: 40px;
      }
      .title-detail {
          margin-top: 10px;
          font-size: 16px;
      }
       }
  }
  .text {
    width: 100%;
    height: calc(100% - 108px);
    text-align: center;
    position: absolute;
    top: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-paly {
        width: 100px;
        height: 100px;
    }
    .look-video {
        margin-top: 15px;
        font-size: 12px;
        line-height: 17px;
    }
  }
}
</style>
